<template>
  <div>
    <v-container fluid v-if="!idParcheggio">
      <v-row align="center" justify="space-around" dense>
        <v-col cols="12" class="mt-5">
          Seleziona la posizione di salvataggio e crea un nuovo conto per aggiungere i prodotti<v-btn icon @click="onRefresh" :loading="refreshing"><v-icon>mdi-refresh</v-icon></v-btn>
        </v-col>
        <v-col class="mt-5 d-flex ml-5 mr-5" v-for="ps in posizioni" :key="ps">
          <v-btn :color="ps === posizione ? 'primary' : ''" :disabled="posizioneOccupata(ps)" @click="selezionaPosizione(ps)">{{`${zonaPark}P ${ps}`}}</v-btn>
          <v-btn icon :disabled="!posizioneOccupata(ps)" @click="svuotaPosizione(ps)"><v-icon>{{ posizioneOccupata(ps) ? 'mdi-delete-outline' : '' }}</v-icon></v-btn>
        </v-col>  
        <v-col cols="12" class="mt-5"><v-btn color="success" block @click="onNuovoConto">NUOVO</v-btn></v-col>  
      </v-row>
    </v-container>
    <v-container fluid v-if="idParcheggio">
      <v-row align="center" justify="space-around" no-gutters>
        <v-col cols="12" class="text-center mb-5">
          <v-btn icon @click="onToggleLeggiBarcode"><v-icon>mdi-barcode-scan</v-icon></v-btn>
          <v-badge bordered color="blue darken-2" :content="numeroItemsConto" overlap :value="numeroItemsConto">
            <v-btn color="blue lighten-5" @click.stop="mostraCarrello">
              POSIZIONE SELEZIONATA: {{`${zonaPark}P ${posizione}`}}
            </v-btn>            
          </v-badge>
          </v-col>
        <v-col cols="12">
          <!-- <v-text-field v-model="filtro.codice" dense outlined label="Codice" clearable @keydown.enter="onRicerca" @click:clear="onCancel"></v-text-field> -->
          <component :is="'pProdotto'" :item="itemProdotto" :base="base" @change="onChangeProdotto" :updateTrigger="updateTrigger" />
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="filtro.barcode" :inputmode="mostraTastiera ? 'text' : ''" dense label="Barcode" autofocus clearable @keydown.enter="onRicerca" @click:clear="onCancel" ref="barcode">
            <template v-slot:prepend-inner>
              <v-icon v-if="mostraTastiera" color="success">mdi-keyboard-outline</v-icon>
            </template>
            <template v-slot:append-outer>
              <v-icon v-if="isMobile" color="info" @click="onToggleTastiera" title="Tastiera virtuale">mdi-keyboard-outline</v-icon>
            </template>
          </v-text-field>
        </v-col>   
      </v-row>
    </v-container>
    <v-container fluid v-if="prodotto">
      <v-row align="center" justify="space-around" no-gutters>
        <v-col cols="12">Giacenza: {{ giacenza }}<v-btn icon color="primary" class="ml-3" @click="modificaGiacenza"><v-icon small>mdi-store-edit-outline</v-icon></v-btn></v-col>
        <v-col cols="12">Barcode: {{ barcode }}<v-btn icon color="primary" class="ml-3" @click="modificaBarcode"><v-icon small>mdi-barcode</v-icon></v-btn></v-col> 
        <v-col cols="12">Id: {{ idProdotto }}</v-col> 
        <v-col cols="12">Codice: {{ codice }}</v-col> 
        <v-col cols="12">Prodotto: {{ descrizione }}</v-col> 
        
      </v-row>
      <v-row align="center" justify="space-around" no-gutters class="mt-3">
        <v-col cols="12"><v-text-field v-model="quantita" ref="el_quantita" type="number" dense outlined label="QTA" clearable :max="giacenza" ></v-text-field></v-col>
<!--          <v-col cols="12" />
        <v-col cols="12"><v-slider
          v-model="quantita"
          :rules="rules"
          label="Quantità"
          step="1"
          thumb-label="always"
          ticks
        ></v-slider></v-col> -->
        <v-col cols="12" ><v-btn color="success" block @click="onAcquista">AGGIUNGI</v-btn></v-col>
        <v-col cols="12" class="mt-3"><v-btn color="" block small @click="onCancel">ANNULLA</v-btn></v-col>  
        <v-spacer></v-spacer>
        <v-col cols="12" class="mt-3"><v-btn color="primary" block small @click="onChiudiConto">CHIUDI CONTO</v-btn></v-col>  
      </v-row>
    </v-container>
    <v-container fluid v-if="idParcheggio && !prodotto">
      <v-spacer></v-spacer>
      <v-row justify="space-around" dense class="mt-5">
        <v-col cols="12" class="mt-3"><v-btn color="" block small @click="onCancel">ANNULLA</v-btn></v-col>
        <v-col cols="12" class="mt-3"><v-btn color="primary" block small @click="onChiudiConto">CHIUDI CONTO</v-btn></v-col>
      </v-row>
    </v-container>
    <carrello :righe="righeConto" :show="showDialogCarrello" @updateScontrino="updateScontrino"/>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn  color="blue" text v-bind="attrs" @click="snackbar = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>
    <camera-scanner v-if="leggiBarcode" @decoded="onDecode" />
    <edit-barcode :show="showAggiornaBarcode" :barcode="barcode" @aggiorna-barcode="aggiornaBarcode" />
    <edit-giacenza :show="showAggiornaGiacenza" :giacenza="+parseFloat(giacenza).toFixed(2)" @aggiorna-giacenza="aggiornaGiacenza" />
      <dialog-conferma
        :show="dialogConferma"
        :title="titoloConferma"
        :message="messaggioConferma"
        :onlyOk="false"
        :confirmText="confirmText"
        @dialog-confirm="onConferma"
        @dialog-cancel="dialogConferma = false"
      />    
  </div>
</template>

<script>
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import pProdotto from '@/views/drawer/elementi/prodotto'
import prodotti from '@/services/prodottiService.js'
import configurazioni from '@/services/configurazioniService.js'
import scontrini from '@/services/scontriniService.js'
import utility from '@/services/utilityService.js'
import Carrello from './carrello'
import { isMobile } from '@/mixins/isMobile.js'
import CameraScanner from './CameraScanner'
import EditBarcode from './editBarcode'
import EditGiacenza from './editGiacenza'
import DialogConferma from '@/components/dialogConferma'

export default {
  components: {
    Carrello,
    pProdotto,
    CameraScanner,
    EditBarcode,
    EditGiacenza,
    DialogConferma
  },
  mixins: [
    isMobile
  ],
  data(vm) {
    return {
      filtro: {
        codice: '',
        barcode: '' // '8054899554351'
      },
      prodotto: null,
      conto: null,
      articoli: [],
      quantita: 1,
      snackbar: false,
      snackColor: '',
      snackText: 'Il prodotto è stato aggiunto al carrello',
      timeout: 2000,
      base: '',
      updateTrigger: 1,
      idParcheggio: null,
      posizione: 0,
      parcheggiati: [],
      showDialogCarrello: false,
      mostraTastiera: false,
      leggiBarcode: false,
      refreshing: false,
      // posizioni: ['1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.7', '2.1', '2.2', '2.3', '2.4', '2.5'],
      posizioni: [],
      digitato: '',
      showAggiornaBarcode: false,
      showAggiornaGiacenza: false,
      soloGiacenzaPositiva: false,
      dialogConferma: false,
      titoloConferma: '',
      messaggioConferma: '',
      onlyOk: false,
      onConferma: vm.onSvuotaPosizione,
      confirmText: 'SI',
      posizioneDaSvuotare: null,
      zone: [],
      zonaPark: 0
    }
  },
  watch: {
    device(value) {
      this.base = value.base
    }
  },
  async mounted() {
    if (this.device) {
      this.base = this.device.base
      await this.aggiornaParcheggiati()
    }
    if (this.isMobile) {
      addEventListener("click", function() {
        const el = document.documentElement
        const rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen
        rfs.call(el)
      })
    }

    if (!this.base) {
      const device = this.device
      this.base = device.base
    }
    
    const backoffice = await configurazioni.getConfigurazione(this.base, 'mobile', 'platform')
    this.soloGiacenzaPositiva = _.get(backoffice, 'impostazioni.soloGiacenzaPositiva', true)

    var result = await configurazioni.getConfigurazione(this.base, 'parcheggio', 'zone')
    this.zone = result

    const zonaPark = _.get(backoffice, 'impostazioni.ordini.creazione.parcheggio.zona', 0)
    this.zonaPark = zonaPark

    const righe = _.get(backoffice, 'impostazioni.ordini.creazione.parcheggio.righe', 7)
    const colonne = _.get(backoffice, 'impostazioni.ordini.creazione.parcheggio.colonne', 7)
    this.posizioni = []
    for (let r of _.range(1, righe + 1)) {
      for (let c of _.range(1, colonne + 1)) {
        this.posizioni.push(`${r}.${c}`)
      }
    }
  },
  computed: {
    descrizione() {
      if (!this.prodotto) return ''
      let p = this.prodotto
      return `${p.descrizione} ${_.get(p, 'varianti.variante1.descrizione', '')} ${_.get(p, 'varianti.variante2.descrizione', '')}` 
    },
    giacenza() {
      return this.prodotto && this.prodotto.magazzino ? this.prodotto.magazzino.giacenza : 0
    },
    barcode() {
      return _.get(this, 'prodotto.ricercaBarcode','')
    },
    codice() {
      return _.get(this, 'prodotto.codice','')
    },
    idProdotto() {
      return _.get(this, 'prodotto._id','')
    },
    rules() {
      return [
        v => v <= this.giacenza || `Solo ${this.giacenza} ${this.giacenza === 1 ? 'pezzo' : 'pezzi'} in giacenza`
      ] 
    },
    device() {
      return this.$store.getters['mobile/device']
    },
    calcPosizione() {
      // TODO: Va gestita completamente: occupati, cancella, etc.
      return `${this.zonaPark}.${this.posizione}`
    },
    numeroItemsConto() {
      if (!this.conto) return 0
      const righe = this.conto.righe
      const itemsCount = _.sumBy(righe, (o) => parseFloat(o.quantita))
      return itemsCount
    },
    righeConto() {
      return this.conto ? this.conto.righe : []
    },
    itemProdotto() {
      return {
        "model": "filtro.codice",
        "valueField": "filtro.codice",
        "label": "Codice",
        "type": "Prodotto",
        "required": false,
        "readOnly": false,
        "addForbidden": true,
        value: ''         
      }
    }
  },
  methods: {
    async modificaBarcode() {
      this.showAggiornaBarcode = true
      await this.$nextTick()
      this.showAggiornaBarcode = false
    },
    async aggiornaBarcode(barcode) {
      const prodotto = await prodotti.getProdotto(this.base, this.idProdotto)
      const adesso = new Date()
      const nomeImport = 'Modifica da dispositivo mobile'
      if (prodotto) {
        let newBarcode = {
          codice: barcode,
          da: adesso,
          attivo: true,
          importOn: nomeImport
        }
        if (!prodotto.barcode || prodotto.barcode.length === 0) {
          prodotto.barcode = []
        } else {
          prodotto.barcode.forEach(el => {
            if (el.attivo) {
              el.attivo = false
              el.a = adesso
              el.importOff = nomeImport
            }
          })
        }
        prodotto.barcode.push(newBarcode)
        await prodotti.setProdotto(this.base, prodotto)
        this.snackColor = ''
        this.snackText = 'Il barcode del prodotto è stato aggiornato'
        this.snackbar = true
      }
    },
    async modificaGiacenza() {
      this.showAggiornaGiacenza = true
      await this.$nextTick()
      this.showAggiornaGiacenza = false
    },
    async aggiornaGiacenza(giacenza) {
      const prodotto = await prodotti.getProdotto(this.base, this.idProdotto)
      if (prodotto && !isNaN(giacenza)) {
        giacenza = +parseFloat(giacenza).toFixed(2)
        const delta = +(giacenza - parseFloat(_.get(prodotto, 'magazzino.giacenza', 0))).toFixed(2)
        _.set(prodotto, 'magazzino.giacenza', giacenza)
        await prodotti.setProdotto(this.base, prodotto)
        // TODO: Movimento di rettifica giacenza
        let comparazione = [{
          _id: this.idProdotto,
          delta: delta,
          tipoMovimento: 'rettifica',
          magazzino: 'mag01'
        }]
        await utility.creaMovimentoRettificaInventario(this.base, comparazione, true)
        this.prodotto.magazzino.giacenza = giacenza
      }
    },    
    /*     impostaStati() {
      for (const item of this.parcheggiati) {
        const zona = item.parcheggio.zona
        const p = item.parcheggio.posizione
        const el = this.zone.find(x => x.codice === zona).posizioni.find(x => x.codice === p)
        if (el) {
          el.payload.stato = item.parcheggio.stato
          el.payload.conto = item._id
        }
      }
    },  */
    async leggiTastiera(event) {
      if (event.keyCode === 13) {
        this.filtro.barcode = this.digitato
        this.digitato = ''
        await this.onRicerca()
      } else {
        this.digitato = this.digitato + String(event.key)
      }
    },
    async onRefresh() {
      this.refreshing = true
      await this.$nextTick()
      await this.aggiornaParcheggiati()
      this.refreshing = false
    },
    onToggleLeggiBarcode() {
      this.leggiBarcode = !this.leggiBarcode
    },
    async onDecode(text) {
      this.leggiBarcode = false
      this.filtro.barcode = text
      await this.onRicerca()
    },
    onToggleTastiera() {
      this.mostraTastiera = !this.mostraTastiera
    },
    async mostraCarrello() {
      this.showDialogCarrello = true
      await this.$nextTick()
      this.showDialogCarrello = false
    },
    async aggiornaParcheggiati() {
      this.parcheggiati = await scontrini.getParcheggiati(this.base)
    },
    selezionaPosizione(ps) {
      this.posizione = ps
    },
    posizioneOccupata(ps) {
      // TODO: va considerata la gestione delle zone, di tutte le righe e colonne
      const posizione = `${this.zonaPark}.${ps}`
      const occupato = this.parcheggiati.find(x => x.parcheggio.posizione === posizione)
      return occupato ? true : false
    },
    async svuotaPosizione(ps) {
      if (!this.posizioneOccupata(ps)) return
      this.posizioneDaSvuotare = ps

      this.titoloConferma = 'Svuotamento parcheggio',
      this.messaggioConferma = `Confermi eliminazione contenuto posizione: ${this.zonaPark}.${ps} ?`,

      this.dialogConferma = true
    },
    async onSvuotaPosizione() {
      const ps = this.posizioneDaSvuotare
      const posizione = `${this.zonaPark}.${ps}`
      const scontrino = this.parcheggiati.find(x => x.parcheggio.posizione === posizione)
      const idScontrino = scontrino && scontrino._id
      if (idScontrino) {
        await scontrini.annullaParcheggio(this.base, idScontrino)
        await this.onRefresh()
      }
      this.dialogConferma = false
      this.posizioneDaSvuotare = null
    },    
    async onChiudiConto() {
      this.idParcheggio = null
      this.prodotto = null
      this.conto = null
      this.posizione = 0
      await this.aggiornaParcheggiati()
    },
    async onNuovoConto() {
      try {
        // await this.$dialog.confirm({ title: 'Nuovo Ordine', message: 'Confermi creazione nuovo ordine (parcheggiato) ?', cancelButtonText: 'NO', confirmButtonText: 'SI' })
        // TODO: Leggere posizione vuota o richiedere dove salvare
        if (this.posizione === 0) return
        this.idParcheggio = await scontrini.creaScontrino(this.base, {}, { zona: '2', posizione: this.calcPosizione })
      } catch {

      }
    },
    async onAcquista() {
      if (this.quantita === 0) return
      if (this.quantita > this.giacenza && this.soloGiacenzaPositiva) {
        this.snackColor = 'red accent-2'
        this.snackText = 'Giacenza non sufficiente !!'
        this.snackbar = true
        return
      }
      const p = this.prodotto
      const riga = {
        codice: p._id,
        codiceProdotto: p.codice,
        barcode: p.ricercaBarcode,
        variante1: _.get(p, 'varianti.variante1.codice', ''),
        variante2: _.get(p, 'varianti.variante2.codice', ''),
        quantita: this.quantita,
        descrizione: `${p.descrizione} ${_.get(p, 'varianti.variante1.descrizione', '')} ${_.get(p, 'varianti.variante2.descrizione', '')}`.trim(),
        prezzoUnitario: +p.prezzo.L1.base.toFixed(2),
        prezzo: (p.prezzo.L1.base * this.quantita).toFixed(2),
        aliquota: p.datiFiscali.aliquoteIva[0].valore,
        tipo: 'prodotto',
        tipoBene: 'PRODOTTO',
        repartoFiscale: p.datiFiscali.reparto.stampante,
        prezzoAcquisto: _.get(p, 'magazzino.prezzoAcquisto', ''),
        rigaCodice: uuidv4()
      }
      this.conto = await scontrini.addRigaScontrino(this.base, this.idParcheggio._id, riga)
      this.snackColor = ''
      this.snackText = 'Il prodotto è stato aggiunto al carrello'
      this.snackbar = true
      setTimeout(() => this.onCancel(), 2000)
      await this.$nextTick()
      const br = this.$refs['barcode']
      br.focus()
    },
    onCancel() {
      this.prodotto = null
      this.filtro.barcode = ''
      this.filtro.codice = ''
      this.updateTrigger++
    },
    async onRicerca() {
      this.filtro.tipoItem = 'PRODOTTO'
      this.filtro.codice = ''
      setTimeout(async () => {
        await this.getDataFromApi()
        await this.$nextTick()
        const qe = this.$refs['el_quantita']
        if (qe) {
          qe.focus()
        }        
      }, 500)
    },
    async updateScontrino() {
      this.conto = await scontrini.updateScontrino(this.base, this.idParcheggio._id, this.conto.righe)
    },
    async onChangeProdotto(data) {
      this.filtro.barcode = ''
      if (data) {
        const p = data.item
        const elenco = await prodotti.getProdottiByIds(this.base, [p.id])
        this.prodotto = Object.assign({}, elenco[0])
        /*         this.prodotto = {
          _id: p.id,
          descrizione: `${p.descrizione} ${_.get(p, 'variante1.descrizione', '')} ${_.get(p, 'variante2.descrizione', '')}`.trim(),
          ricercaBarcode: p.ricercaBarcode,
          magazzino: {
            giacenza: p.giacenza
          }
        } */
        this.filtro.codice = ''
        this.quantita = 1
        await this.$nextTick()
        const qe = this.$refs['el_quantita']
        if (qe) {
          qe.focus()
        }

      } else {
        this.onCancel()
        this.snackColor = 'red accent-2'
        this.snackText = 'Prodotto non trovato'
        this.snackbar = true          
      }
    },
    async getDataFromApi () {
      try {
/*         this.snackColor = 'red accent-2'
        this.snackText = `Prodotto cercato: C=${this.filtro.codice || ''}  B=${this.filtro.barcode || ''}`
        this.snackbar = true   */      
        if (this.filtro.codice === '' && this.filtro.barcode === '') return
        this.loading = true

        const data = await prodotti.getFilteredProducts(this.base, this.filtro)
        this.articoli = data && data.total_rows > 0 ? data.rows.map(x => x.doc) : []
        if (this.articoli.length > 0) {
          this.prodotto = Object.assign({}, this.articoli[0])
          this.filtro.barcode = ''
          this.quantita = 1
        } else {
          this.snackColor = 'red accent-2'
          this.snackText = `Prodotto non trovato: ${this.filtro.codice || ''}${this.filtro.barcode || ''}`
          this.onCancel()
          this.snackbar = true          
        }
        this.totaleArticoli = data.total_rows
      } catch(err) {
        this.articoli = []
        this.totaleArticoli = 0
      } finally {
        this.loading = false
      }
    }
  }
}
</script>